export function OysterIcon({ color = "white", size = "50px" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        fill="currentColor"
        d="M126.57,64.91A61.956,61.956,0,0,0,122,46.84a62.748,62.748,0,0,0-15.42-21.8A62.107,62.107,0,0,0,64.95,8.78h-.3a61.824,61.824,0,0,0-22.2,4.08A62.814,62.814,0,0,0,8.14,44.94,61.875,61.875,0,0,0,2.73,64.91,13.079,13.079,0,0,0,6.51,75.49a.64.64,0,0,0,.15.13l37.36,25.13a9.623,9.623,0,0,0-1.35,1.13,9.384,9.384,0,0,0-2.78,6.7,9.489,9.489,0,0,0,9.48,9.49H78.76a9.48,9.48,0,0,0,5.9-16.9l37.97-25.55a.989.989,0,0,0,.15-.12A13.138,13.138,0,0,0,126.57,64.91ZM48.785,101.25,8.923,46.881A1,1,0,1,1,10.537,45.7L51.265,101.25Zm6.206,0-32.2-73.98a1,1,0,0,1,1.834-.8L57.173,101.25Zm4.764,0L42.03,14.981a1,1,0,0,1,1.959-.4L61.8,101.25Zm6.195,0h-2V10.78a1,1,0,1,1,2,0Zm2.13,0L85.72,14.76a1,1,0,0,1,1.96.4L70.122,101.25Zm6.917,0H72.812l32.633-74.083a1,1,0,1,1,1.83.807Zm46.171-52.487L81.56,101.25H79.053l40.518-53.692a1,1,0,0,1,1.6,1.205Z"
      />
    </svg>
  );
}
