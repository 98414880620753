import Container from "../blocs/Container";
import Footer from "../blocs/Footer";
import Header from "../blocs/Header";
import SubHeader from "../blocs/SubHeader";

function MentionsPage() {
  return (
    <>
      <Header />
      <SubHeader />

      <Container>
        <div className="sm:p-0 p-5">
          <h2 className="font-cursive text-xl font-bold mb-3">
            Mentions légales
          </h2>
          <p className="font-sans leading-relaxed mb-3">
            La EARL "Les Huitres de Sterec" prend toutes les mesures possibles
            pour garantir les informations fournies sur ce site. Toutefois, La
            EARL "Les Huitres de Sterec" n'engage en aucun cas sa responsabilité
            en cas d'erreurs ou d'omissions contenues sur celui-ci. Les
            informations fournies sur le site internet peuvent faire l'objet de
            modifications à tout moment et sans préavis. Vous assumez les
            risques afférents à l'utilisation et à l'accès de ce site Internet.
          </p>

          <p className="font-sans leading-relaxed">
            La EARL "Les Huitres de Sterec" en ce qui concerne la création et la
            présentation ne pourra être tenu responsable de tout dommage
            accessoire, spécial, prononcé à titre de sanction, incident,
            exemplaire ou indirect découlant de l'utilisation de ce site. Dans
            la mesure permise par la réglementation applicable et sans limiter
            la portée de ce qui précède, le contenu du site est fourni « en
            l'état », sans garantie, expresse ou implicite, d'aucune sorte,
            notamment de qualité marchande, d'adéquation à un usage particulier,
            d'absence de virus et d'absence de contrefaçon. Certains pays
            n'autorisent pas la limitation de garanties implicites ou la
            limitation de responsabilité pour les dommages indirects ou
            incidents, de sorte que les limitations ci-dessus peuvent ne pas
            vous être applicables. • Politique de confidentialité de notre site
            Cette politique de confidentialité est également fournie
            conformément à l'article 13, décret-loi 196/2003 (protection des
            données à caractère personnel) pour ceux qui interagissent avec les
            services Web accessibles en ligne à l'adresse suivante:
            www.huitres-de-sterec.fr Les utilisateurs qui visitent le site:
            www.huitres-de-sterec.fr acceptent implicitement les pratiques
            décrites dans cette politique de confidentialité. • Contrôleur de
            données La consultation de ce site peut donner lieu au traitement de
            données relatives à des personnes identifiées ou identifiables. Le
            responsable de ce traitement est la EARL "Les Huitres de Sterec" -
            Impasse de Feunteun Bol - TERENEZ - 29630 PLOUGASNOU - FRANCE • Site
            de traitement des données Le traitement lié aux services Web fournis
            par ce site est effectué par le personnel technique chargé du dit
            traitement ou par les personnes engagées occasionnellement pour des
            opérations de maintenance. Aucune donnée dérivée du service Web
            n'est communiquée ou portée à la connaissance du public. • Finalités
            et utilisation des informations Les données à caractère personnel
            fournies par les utilisateurs qui demandent la transmission
            d'informations ou s'inscrivent pour recevoir des bulletins
            d'informations sont utilisées aux seules fins de fournir le service
            demandé. Elles peuvent aussi être utilisées dans le cadre des
            activités normales de la Société aux fins suivantes : a) gestion de
            la relation utilisateur/client ; b) mener à bien son activité
            économique ; c) autres fins, liées aux activités de la Société ;
            notamment amélioration des produits et services proposés, même s'ils
            ne sont pas étroitement liés aux services demandés par le Soumetteur
            : étude de marché concernant la satisfaction du client, envoyée
            directement ou par l'intermédiaire d'agences spécialisées, par
            téléphone, questionnaires ou rendez-vous personnels, fourniture
            d'informations relatives aux services proposés et aux produits
            commercialisés par la Société. • Type de données Les systèmes
            informatiques et procédures logicielles utilisés pour le
            fonctionnement de ce site acquièrent certaines données à caractère
            personnel dans le cadre d'une d'utilisation normale ; leur
            transmission est implicite lors de l'utilisation de protocoles de
            communication Internet (Internet Protocol). Lesdites informations ne
            sont pas collectées pour être associées aux parties identifiées mais
            peuvent, par nature, rendre possible l'identification des
            utilisateurs via le traitement et l'association à des données
            détenues par des tiers. Ces données englobent les adresses IP ou les
            noms de domaine des ordinateurs dont se servent les utilisateurs qui
            se connectent au site, les adresses contenues dans les URI (Uniform
            Resource Identifier) des ressources demandées, l'heure de la
            demande, la méthode utilisée pour envoyer la demande au serveur, la
            taille du fichier obtenu en réponse, le code numérique indiquant
            l'état de la réponse donnée par le serveur (terminé, erreur, etc.)
            et d'autres paramètres relatifs au système d'exploitation et à
            l'environnement informatique de l'utilisateur. Ces données ne sont
            utilisées que pour obtenir des informations statistiques anonymes
            relatives à l'utilisation du site et effectuer des vérifications
            fonctionnelles, et sont supprimées immédiatement après le
            traitement. Les données pourront être utilisées pour établir la
            responsabilité en cas de criminalité informatique affectant ce site.
            • Données fournies volontairement par les utilisateurs L'envoi
            facultatif, explicite et volontaire d'un message électronique aux
            adresses mentionnées sur ce site entraîne l'acquisition ultérieure
            de l'adresse de l'expéditeur, qui est nécessaire pour répondre aux
            demandes, et de toutes autres données à caractère personnel
            contenues dans le message électronique. Des informations résumées
            sur la confidentialité seront mentionnées ou affichées
            occasionnellement sur les pages du site relatives à des services
            spécifiques sur demande. Les utilisateurs sont libres de fournir les
            données à caractère personnel mentionnées dans les modules de
            demande, ou dans tous les cas sur la page « Contact», pour demander
            des informations ou d'autres communications. La non-fourniture de
            ces données est susceptible de rendre impossible l'obtention des
            informations demandées. • Méthodes de traitement Les données à
            caractère personnel sont traitées par des dispositifs automatiques
            uniquement pendant le temps strictement nécessaire pour atteindre
            les objectifs pour lesquels elles ont été recueillies. Des mesures
            de sécurité spécifiques sont adoptées afin d'empêcher les pertes,
            l'utilisation illégale ou inappropriée des données et leur accès non
            autorisé. • Droits des parties concernées Les parties auxquelles les
            données à caractère personnel font référence sont autorisées, à tout
            moment, à obtenir confirmation de l'existence ou non desdites
            données, à en connaître le contenu et l'origine et à vérifier leur
            exactitude ou demander l'ajout ou la mise à jour ou la rectification
            desdites données (article 7, décret-loi 196/2003). Conformément à la
            stipulation susmentionnée, les parties sont autorisées à demander la
            suppression, la conversion en une forme anonyme ou le verrouillage
            des données traitées en infraction avec la loi et dans tous les cas
            à s'opposer, pour des motifs légitimes, au traitement des données à
            caractère personnel. Ce document, publié à l'adresse suivante:
            www.huitres-de-sterec.fr constitue la « Politique de confidentialité
            » de ce site et peut faire l'objet de mises à jour (les versions
            ultérieures sont conservées à la même adresse à des fins de
            consultation).
          </p>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default MentionsPage;
